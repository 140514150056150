<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :name="field"
    :vid="vid"
    :rules="rules">
    <!--Field-->
    <div class="select__search"
         :class="{'errors__field': errors[0]}">
      <model-list-select :list="options"
                     option-value="id"
                     option-text="title"
                    :isDisabled="disabled"
                     @input="$emit('change', $event)"
                     :value="value">
      </model-list-select>
    </div>

    <!--Message Error-->
    <span
      v-if="errors[0]"
      class="errors">
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'

export default {
  name: 'InputSelectSearch',

  components: {
    ModelListSelect
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    field: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number],
      default: ''
    },

    rules: {
      type: String,
      default: ''
    },

    vid: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    options: {
      type: [Array, Object],
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.input__field {
  position: relative;
  input {
    border-radius: 6px;
    font-size: 18px;
    padding: 16px 14px;
    height: 53px;
    width: 100%;
  }
  datalist {
    position: absolute;
    left: 0;
    top: 53px;
    width: 100%;
    background: #ffffff;
    padding: 16px 14px;
    border-radius: 0 0 5px 5px;
    overflow-y: auto;
    z-index: 1;
    option {
      background-color: #ffffff;
      padding: 4px;
      margin-bottom: 1px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}

</style>
