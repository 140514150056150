<template>
  <div class="cate-orange">
    <!-- title -->
    <h2>買うものリスト</h2>

    <!-- btn add -->
    <button @click="displayModal('create')"
            class="btn-add">
      <plus-icon />
      新規登録する
    </button>

    <!-- cart list -->
    <div class="wrap-cart-list">
      <!-- title table -->
      <ul class="title-list">
          <li>タイトル</li>
          <li>メモ</li>
          <li>期限</li>
      </ul>

      <!-- list cart -->
      <ul class="cart-list" v-if="pagination.total > 0">
        <li v-for="(item, index) in cartList" :key="index">
          <div class="content">
            <p v-html="handleSanitizeHtml(item.title)"/>
            <p><span class="line-clamp-01" v-html="handleSanitizeHtml(item.note)" />
            <p>{{ item.deadline_datetime | formatDate('YYYY/MM/DD') }}</p>
          </div>

          <div class="btn-control">
            <button @click="displayModal('detail', item.id)">詳細</button>
            <button @click="displayModal('delete' , item.id)" class="btn-delete">削除</button>
          </div>
        </li>
      </ul>

      <b class="data-empty" v-else>該当するデータが見つかりませんでした。</b>

      <!-- pagination -->
      <div v-if="pagination.totalPages > 1"
           class="wrap-paginate">
        <Pagination :total-page="pagination.totalPages"
                    @change-page = changePage
                    :current-page="pageActive" />
      </div>
    </div>

    <!-- modal -->
    <Modal v-if="flagModal && flagModalCart"
           @close-modal="displayModal('close')" >

      <!-- BODY MODAL -->
      <div slot="body">
        <!-- modal create or edit -->
        <ValidationObserver
          tag="form"
          ref="observer"
          class="form"
          v-if="displayScreen === 'create' || displayScreen === 'edit'"
          @submit.prevent="validateBeforeSubmit()">

          <!-- Error server -->
          <ul v-if="errors.length"
              class="error__server">
            <li>{{ errors }}</li>
          </ul>

          <div class="form__cart">
            <div class="form__cart__group">
              <!-- title -->
              <div class="form__control">
                <label>
                  タイトル
                  <span class="required"
                        v-text="'必須'"/>
                </label>
                <div class="form__control__input">
                  <InputField v-model="form.title"
                              class="w-100"
                              :max-length="50"
                              :disabled="displayScreen === 'detail'"
                              rules="required|max:200"
                              field="タイトル"/>
                </div>
              </div>

              <!-- advertising -->
              <div class="form__control form__control--select ">
                <label>
                  関連広告
                </label>

                <div class="form__control__input">
                  <InputSelectSearch :value="form.advertise_id"
                                     field="関連広告"
                                     v-if="displayScreen === 'create'
                                            || (displayScreen === 'edit' && !checkAdvertiseAvailable)"
                                     class="select"
                                     @change="form.advertise_id = $event"
                                     :options="advertiseList"/>

                  <p v-else-if="displayScreen === 'edit' && checkAdvertiseAvailable"
                                v-text="displayCartTitle"/>

                  <router-link :to="{ name: 'AdvertisingDetail', params: { id: form.advertise_id } }"
                               v-if="displayScreen === 'edit'
                                     && checkAdvertiseAvailable">
                    広告ページを見る
                  </router-link>
                </div>
              </div>

              <!-- date add -->
              <div class="form__control form__control--select ">
                <label>
                  期限
                </label>

                <div class="form__control__input">
                  <InputDatepicker v-model="form.deadline_datetime"
                                   class="w-30 time"
                                   field="期限"/>
                </div>
              </div>

              <!-- note add -->
              <div class="form__control">
                <label>
                  メモ
                </label>

                <div class="form__control__input">

                  <TextAreaField v-model="form.note"
                                 :rows="4"
                                 :max-length="2000"
                                 rules="max:2000"
                                 class="w-100"
                                 field="メモ"
                                 vid="note"/>
                </div>
              </div>
            </div>
          </div>
        </ValidationObserver>

        <!-- modal detail -->
        <div class="modal-detail form__cart"
             v-else-if="displayScreen ==='detail'">

          <div class="fields">
            <div class="fields__content">
              <p v-text="'タイトル'"/>
              <span>{{form.title}}</span>
            </div>
            <div class="fields__content">
              <p v-text="'関連広告'"/>
              <div class="fields__content__adv">
                <div class="wrap-content-adv">
                  <span class="line-clamp-01">{{displayCartTitle}}</span>
                </div>

                <router-link :to="{ name: 'AdvertisingDetail', params: { id: form.advertise_id || 1 } }"
                             v-if="checkAdvertiseAvailable"
                             v-text="'広告ページを見る'"/>
              </div>
            </div>
            <div class="fields__content">
              <p v-text="'期限'"/>
              <span v-if="form.deadline_datetime">{{form.deadline_datetime | formatDate('YYYY/MM/DD')}}</span>
              <span v-else>ー</span>
            </div>
            <div class="fields__content">
              <p v-text="'メモ'"/>
              <span>{{form.note || 'ー'}}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- FOOTER MODAL -->
      <div class="btn-footer"
           slot="footer">
        <!--button submit-->
        <button @click="validateBeforeSubmit()"
                v-if="displayScreen === 'create' || displayScreen === 'edit'"
                class="btn btn__submit">
          登録する
          <ChevronRightIcon size="24"/>
        </button>

        <div class="btn_group"
             v-if="displayScreen === 'detail'">
          <button class="btn-submit"
                  @click="displayModal('edit', cartId)">
            編集する
            <ChevronRightIcon size="24"/>
          </button>
        </div>
      </div>
    </Modal>

    <!-- MODAL DELETE -->
    <Modal @close-modal="displayModal('close')"
           class="modal-center"
           v-if="flagModal && flagModalDelete">
      <div class="modal-delete"
           slot="body">
        <p>本当に削除してもよろしいですか？</p>
      </div>

      <div slot="footer"
           class="wrap-btn-delete">
        <button @click="displayModal('close')">キャンセル</button>
        <button @click="handleDelete" class="delete">削除</button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { PlusIcon, ChevronRightIcon } from 'vue-feather-icons'
import Pagination from '@/components/Pagination'
import Modal from '@/components/advertising-detail/Modal'
import InputField from '@/components/form/InputField'
import TextAreaField from '@/components/form/TextAreaField'
import InputDatepicker from '@/components/form/InputDatepicker'
import { mapActions, mapGetters, mapState } from 'vuex'
import InputSelectSearch from '@/components/form/InputSelectSearch'
import store from '@/store'
import { handleSanitizeHtml } from '@/utils/helpers'
import Common from '@/mixins/common.mixin'

export default {
  name: 'CartList',

  components: {
    InputSelectSearch,
    InputDatepicker,
    TextAreaField,
    InputField,
    Modal,
    Pagination,
    PlusIcon,
    ChevronRightIcon
  },

  mixins: [Common],

  beforeRouteEnter (to, from, next) {
    store.commit('cart/SET_FLAG_MODAL', false)
    next()
  },

  data () {
    return {
      flagModalPage: this.flagModal,
      flagModalCart: false,
      flagModalDelete: false,
      form: {
        title: '',
        advertise_id: '',
        deadline_datetime: '',
        note: ''
      },
      errors: [],
      displayScreen: 'create',
      params: {
        perPage: 20,
        page: 1
      },
      pageActive: 1,
      cartId: null
    }
  },

  computed: {
    ...mapGetters('cart', ['flagModal']),
    ...mapState('cart', ['cartDetail', 'cartList', 'pagination']),
    ...mapState('advertises', ['advertiseList']),

    displayCartTitle () {
      const index = this.advertiseList.findIndex(item => (item.id === this.form.advertise_id))
      if (index > -1) {
        return this.advertiseList[index].title
      }
      return 'ー'
    },

    checkAdvertiseAvailable () {
      if (this.cartDetail && this.cartDetail.advertise_id) {
        const index = this.advertiseList.findIndex(item => (item.id === this.cartDetail.advertise_id))
        return index > -1
      }
      return false
    }
  },

  created () {
    const params = {
      perPage: 20,
      page: 1
    }
    store.dispatch('cart/getListCart', params)

    store.dispatch('advertises/getListAdvertises')

    this.pageActive = this.pagination.currentPage
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },

  methods: {
    handleSanitizeHtml,

    ...mapActions('cart', ['getListCart', 'deleteCart', 'createCart', 'getCartDetail', 'updateCart']),

    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        await this.handleSubmit()
      }
    },

    async handleSubmit () {
      if (this.displayScreen === 'create') {
        const result = await this.createCart(this.form)
        if (result.code === 422) {
          if (this.checkWebview()) {
            this.openPageNative('PageNotFound')
          } else await this.$router.push({ name: 'PageNotFound' })
        }
      } else if (this.displayScreen === 'edit') {
        // reset id advertise ID if advertise is unavailable
        if (this.displayCartTitle === 'ー') {
          this.form = {
            ...this.form,
            advertise_id: ''
          }
        }
        const result = await this.updateCart([this.cartId, this.form])
        if (result.code === 422) {
          if (this.checkWebview()) {
            this.openPageNative('PageNotFound')
          } else await this.$router.push({ name: 'PageNotFound' })
        }
      }

      // fetch list
      this.params = {
        ...this.params,
        page: this.pageActive
      }
      await this.fetchList(this.params)

      // close modal
      this.$store.commit('cart/SET_FLAG_MODAL', false)
    },

    changePage (page) {
      this.params = {
        ...this.params,
        page: page
      }

      this.pageActive = page
      this.fetchList(this.params)
    },

    // FETCH LIST
    fetchList (params = {}) {
      this.getListCart(params)

      // scroll to top when change page
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },

    displayModal (screen, id) {
      // pass id to modal for delete or edit
      if (id) {
        this.cartId = id
      }

      // display modal cart or delete
      if (screen === 'delete') {
        this.flagModalCart = false
        this.flagModalDelete = true
      } else this.flagModalCart = true

      this.displayScreen = screen
      if (screen === 'detail') {
        this.getCartDetail(id).then(res => {
          if (res.data.success) {
            this.form = {
              title: this.cartDetail.title,
              advertise_id: this.cartDetail.advertise_id,
              deadline_datetime: this.cartDetail.deadline_datetime,
              note: this.cartDetail.note
            }
          }
        })
      } else if (screen === 'create') {
        for (const property in this.form) {
          this.form[property] = null
        }
      }

      // flag modal
      if (screen === 'close') {
        this.$store.commit('cart/SET_FLAG_MODAL', false)
        this.flagModalCart = false
        this.flagModalDelete = false
      } else this.$store.commit('cart/SET_FLAG_MODAL', true)
    },

    async handleDelete () {
      // call api delete cart
      await this.deleteCart(this.cartId)

      // fetch list again
      this.params = {
        ...this.params,
        page: this.pageActive
      }
      this.getListCart(this.params).then(res => {
        // check data in page empty --> fetch data page before
        if (res.data.data.length === 0 && res.data.pagination.count === 0) {
          this.pageActive--
          this.params = {
            ...this.params,
            page: this.pageActive
          }
          this.getListCart(this.params)
        }
      })

      // turn off modal
      this.$store.commit('cart/SET_FLAG_MODAL', false)
      this.flagModalDelete = false
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-family: $font-family-title;
  @include font-size(36px);
  text-align: center;
  color: #C66C44;
  font-weight: normal;
  margin-bottom: 120px;
  line-height: 1;
  @media #{$info-phone} {
    font-size: 32px;
    margin-bottom: 94px;
  }
}
.btn-add {
  display: flex;
  align-items: center;
  padding: 6px 42px;
  justify-content: space-between;
  cursor: pointer;
  font-family: $font-family-title;
  @include font-size(14px);
  background: #C66C44;
  border-radius: 28px;
  color: #ffffff;
  margin-left: auto;
  margin-bottom: 40px;
  @media #{$info-phone} {
    font-size: 14px;
    margin-bottom: 25px;
    padding: 6px 45px;
  }
  svg {
    margin-right: 4px;
    width: 24px;
  }
}
.wrap-cart-list {
  border-radius: 6px;
  border: 6px solid #E7E5DE;
  @include font-size(14px);
  .title-list {
    font-family: $font-family-title;
    display: flex;
    align-items: center;
    background: #FAFAFA;
    @media #{$info-phone} {
      display: none;
      font-size: 14px;
    }
    li {
      padding: 21px 21px 11px;
      @media #{$info-tablet-horizontal} {
        padding: 15px;
      }
      &:first-child {
        width: 295px;
        @media #{$info-tablet-horizontal} {
          width: 200px;
        }
        @media only screen and (max-width: 1000px) {
          width: 135px;
        }
      }
      &:nth-child(2) {
        width: 263px;
        @media #{$info-tablet-horizontal} {
          width: 200px;
        }
        @media only screen and (max-width: 1000px) {
          width: 140px;
        }
      }
    }
  }
  .cart-list {
    border-top: 1px solid #BDBFAE;
    border-bottom: 1px solid #BDBFAE;
    @media #{$info-phone} {
      border: 0;
    }
    li {
      display: flex;
      align-items: center;
      &:nth-child(even) {
        background: #FAFAFA;
      }
      @media #{$info-phone} {
        padding: 13px 9px 13px 15px;
      }
      .content {
        display: flex;
        align-items: center;
        @media #{$info-phone} {
          width: 70%;
          flex-direction: column;
          align-items: flex-start;
          flex: 1;
        }
        p {
          padding: 21px;
          @media #{$info-tablet-horizontal} {
            padding: 15px;
          }
          @media #{$info-phone} {
            padding: 3px 8px 3px 3px;
          }
          &:first-child {
            width: 295px;
            word-break: break-all;
            @media #{$info-tablet-horizontal} {
              width: 200px;
            }
            @media only screen and (max-width: 1000px) {
              width: 135px;
            }
            @media #{$info-phone} {
              width: 100%;
              font-size: 16px;
            }
          }
          &:nth-child(2) {
            width: 263px;
            opacity: 0.6;
            @media #{$info-tablet-horizontal} {
              width: 200px;
            }
            @media only screen and (max-width: 1000px) {
              width: 140px;
            }
            @media #{$info-phone} {
              width: 100%
            }
          }
          &:nth-child(3) {
            width: 120px;
            @media #{$info-tablet-horizontal} {
              width: 110px;
            }
            @media #{$info-phone} {
              width: 100%
            }
          }
        }
      }
      .btn-control {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        @media #{$info-tablet-vertical} {
          flex-wrap: wrap;
          padding: 5px 0;
        }
        @media #{$info-phone} {
          flex-direction: column;
          flex: unset;
        }
        button {
          width: 60px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          border-radius: 3px;
          background: #447CC6;
          cursor: pointer;
          &.btn-delete {
            background: #C64444;
            @media #{$info-tablet-vertical} {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  .data-empty {
    display: block;
    text-align: center;
    padding: 20px;
  }

  .wrap-paginate {
    padding: 37px 0;
    background: #FAFAFA;
    @media #{$info-phone} {
     background: #ffffff;
      padding: 42px 0;
    }
  }
}
.form__cart {
  padding: 22px 25px;
  border-radius: 6px;
  background: #E7E5DE;
  text-align: left;
  margin-bottom: 26px;
  @media #{$info-phone} {
    padding: 17px 18px;
    margin-bottom: 20px;
  }
  .form__control {
    margin-bottom: 20px;
    @media #{$info-phone} {
      margin-bottom: 13px;
    }
    label {
      margin-bottom: 10px;
      display: flex;
      align-self: center;
      @include font-size(18px);
      @media #{$info-phone} {
        font-size: 16px;
        line-height: 1;
        margin-right: 10px;
      }
      .required {
        color: #C66C44;
        @include font-size(14px);
        font-weight: bold;
        line-height: 1;
        margin-left: 10px;
        align-self: center;
        display: inline-block;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    &--select {
      .form__control__input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media #{$info-phone} {
          flex-direction: column;
        }
        p {
          padding: 12px 15px;
          word-break: break-all;
          flex: 1;
          background: #F0EEEA;
          border-radius: 6px;
          @media #{$info-phone} {
            flex-direction: column;
            width: 100%;
          }
        }
        .select {
          flex: 1;
          @media #{$info-phone} {
            width: 100%;
          }
        }
        a {
          flex-shrink: 0;
          width: 152px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $font-family-title;
          @include font-size(16px);
          background: #444444;
          border-radius: 3px;
          color: #ffffff;
          cursor: pointer;
          margin-left: 17px;
          @media #{$info-phone} {
            margin: 14px 0 0;
            height: 44px;
            font-size: 16px;
          }
        }
      }
    }
    &__input {
      .time {
        @media #{$info-phone} {
          width: 100%;
        }
      }
    }
  }
}
.btn__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family-title;
  max-width: 357px;
  width: 100%;
  height: 51px;
  background: #C66C44;
  border-radius: 6px;
  color: #ffffff;
  margin: auto;
  cursor: pointer;
  @media #{$info-phone} {
    height: 44px;
    font-size: 16px;
  }
}
.modal-delete {
  p {
    margin-bottom: 20px;
  }
}

.wrap-btn-delete {
  button {
    padding: 6px 14px;
    color: #ffffff;
    background: #447CC6;
    border-radius: 3px;
    margin: 0 10px;
    cursor: pointer;
    font-family: $font-family-title;
    &.delete {
      background: #C64444;
    }
  }
}
.modal-detail {
  overflow: auto;
  .fields {
    &__content {
      border-bottom: 1px solid #605E58;
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:last-child {
        border-bottom: unset;
      }

      & > span {
        flex-shrink: 0;
        margin: 0;
        word-break: break-all;
        white-space: break-spaces;
        @include font-size(16px)
      }

      &__adv {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5px;
        @media #{$info-phone} {
          flex-direction: column;
        }

        .wrap-content-adv {
          flex: 1;
          background: #F0EEEA;
          border-radius: 6px;
          padding: 9px 15px;
          @media #{$info-phone} {
            width: 100%;
          }
        }

        a {
          width: 152px;
          height: 50px;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          font-family: $font-family-title;
          @include font-size(16px);
          background: #444444;
          border-radius: 3px;
          color: #ffffff;
          cursor: pointer;
          margin-left: 17px;
          @media #{$info-phone} {
            margin: 14px 0 0;
            height: 44px;
            font-size: 16px;
          }
          &.disabled {
            pointer-events: none;
            cursor: not-allowed;
          }
        }

        & > span {
          margin: 0;
          word-break: break-all;
          @include font-size(16px)
        }
      }

      & > p {
        margin-bottom: 0;
        font-weight: bold;
        @include font-size(18px)
      }
    }
  }
}
.btn-footer {
  .btn_group {
    button {
      max-width: 360px;
      width: 100%;
      height: 51px;
      border-radius: 6px;
      background-color: #6F8784;
      color: #fff;
      margin: auto;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn-submit {
      background-color: #c66c44;
    }
  }
}
</style>
